@use 'sass:math';

@mixin flex($direction, $justify, $align) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}

@mixin bodyFont($size, $height, $weight ) {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-size: calc($size / 16) + rem;
	line-height: math.div($height, 16) + rem;
	font-weight: $weight;
}

// break points
@mixin screen($size) {
	@if ($size == ("mac-13")) {
		@media screen and (max-width: 1440px) {
			@content;
		}
	}
	@if ($size == "x-large") {
		@media screen and (max-width: 1280px) {
			@content;
		}
	}
	@if ($size == "large") {
		@media screen and (max-width: 1024px) {
			@content;
		}
	}
	@if ($size == "medium") {
		@media screen and (max-width: 991px) {
			@content;
		}
	}
	@if ($size == "inbetween") {
		@media screen and (max-width: 767.99px) {
			@content;
		}
	} @else if($size == "small") {
		@media screen and (max-width: 576px) {
			@content;
		}
	} @else if($size == "x-small") {
		@media screen and (max-width: 414px) {
			@content;
		}
	} @else if($size == $size) {
		@media screen and (max-width: $size) {
			@content;
		}
	}
}