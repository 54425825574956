@tailwind base;
@tailwind components;
@tailwind utilities;


//// header and layout
.page-cont {
    @apply w-full h-full; 
}

.page-top {
    @apply w-full sticky top-[10vh] md:top-[13vh] xl:top-[12vh] 2xl:top-[10vh] bg-col_f8f8f8 box-border;
}

.page-main-cont {
    @apply pt-4 mb-[2rem] md:mb-[1rem] pb-[2rem] w-full px-4 overflow-y-auto md:px-5 box-border;
}

.content-tab-margin {
    @apply tb:mt-[12.5rem] mt-[8.5rem] border-0;
}

.content-header-margin {
    @apply mt-[8.5rem] border-0;
}

///////// typography ////////
.normal-text {
    @apply font-fontInter text-[0.75rem] md:text-[0.875rem] leading-normal;
}
.small-title-text {
    @apply font-fontInter text-[0.87rem] font-[600] leading-normal capitalize;
}
.big-title-text {
    @apply font-fontInter text-[1.75rem] font-[600] leading-normal;
}
.topic-text {
    @apply font-fontInter text-[0.87rem] md:text-[1.25rem] font-[500] leading-normal capitalize;
}

.header-box-shadow {
    @apply shadow-[0px_4px_44px_2px_rgba(0,0,0,0.08)] bg-white;
}

/////////// buttons //////////
.btn-default {
    @apply rounded-[0.9rem] cursor-pointer px-3 md:px-6 text-[0.875rem] font-normal;
}

.btn-red-active {
    @apply bg-col_e84e40 text-[#FFFF]  cursor-pointer  text-[0.875rem] font-normal;
}

.btn-red-disabled {
    @apply bg-[#F6B3B3] text-[#FFFF] rounded-[0.9rem] cursor-pointer py-2 px-2 md:px-6 text-[0.75rem] md:text-[0.875rem] font-normal;
}

.btn-green-active {
    @apply bg-[#66BB6A] text-[#FFFF] flex items-center justify-center cursor-pointer  text-[0.875rem] font-normal;
}

.btn-green-disabled {
    @apply bg-[#C8E6C9] text-[#FFFF] rounded-[0.9rem] cursor-pointer py-2 px-2 md:px-6 text-[0.875rem] font-normal;
}

.btn-grey {
    @apply bg-[#76818E66] text-[#FFFFFF] rounded-[0.9rem] text-[0.875rem] font-normal py-[0.5rem] px-[1.25rem] flex items-center gap-1 cursor-pointer;
}

.btn-grey-disabled {
    @apply bg-[#ECECEC] text-col_807e7c rounded-[0.9rem] text-[0.875rem] font-normal py-[0.5rem] px-[1.25rem] flex items-center gap-1 cursor-pointer;
}


//////// tabs ///////////

.tab {
    @apply text-[0.75rem] md:text-[0.875rem] capitalize font-normal py-[0.5rem] md:py-[0.7rem] px-[0.8rem] md:px-[1.25rem] rounded-[0.3rem] md:rounded-[0.9rem] cursor-auto;
}


/////////// header /////////
.red-header {
    @apply bg-col_e84e40 rounded-[0.5rem] py-[0.75rem] px-[1rem] flex items-center text-col_ffffff text-[0.875rem] font-[600] leading-normal capitalize;
}

.table-text {
    @apply text-txt_087_normal text-col_525252 capitalize;
}
