.sidebar-icon {
    transform: rotateY(0);
    transition: all 0.6s;
}

.reverse-sidebar-icon {
    transform: rotateY(180deg);
    transition: all 0.6s;
}

/////// Select dropdown component /////
.select-dropdown__menu {
    background-color: blue;
}
.select-dropdown__indicator-separator {
    background-color: green;
    width: 2rem;
}

.basic-single {
    outline: none;
    &:hover {
        background-color: none;
        border: none;
    }
}

.phone-number-input {
    border-radius: 0.5rem;
    background: #F8F8F8;
    // border: 2px solid blue;

    .PhoneInputCountry {
        background: #ECECEC;
        width: 4rem;
        padding: 0.35rem 0.5rem ;
        border-radius: 0.5rem 0 0 0.5rem;
        margin-right: 0;
    }

    .PhoneInputCountryIcon--border {
        width: 2rem;
        height: 1rem;
        box-shadow: none;
    }

    input {
        background: #F8F8F8;
        padding: 0.35rem 0.5rem;
        border-radius: 0 0.5rem 0.5rem 0;
        color: #525252;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: normal;
        font-family: 'Inter', sans-serif;
        width: 100%;

        &::placeholder {
            color: blue;
        }

        &:focus {
            outline: none;
            border: none;
        }
    }
}

.announcement-text {
    &::-webkit-scrollbar {
        display: none;
    }
}


.radio-btn {
    .radio-container {
        display: block;
        position: relative;
        padding-left: 1.5rem;
        // margin-bottom: 12px;
        cursor: pointer;
        // font-size: 0.875rem;
        color: #525252;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .radio-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        .radio-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 1.2rem;
            width: 1.2rem;
            background-color: #F8F8F8;
            border-radius: 50%;
            border: 1px solid #e1dfdf;
        }

        // &:hover input ~ .radio-checkmark {
        //     background-color: #ccc;
        // }

        .radio-input:checked ~ .radio-checkmark {
            background-color: #E84E40;
        }

        &.radio-checkmark:after {
            display: none;
        }

        .radio-input:checked ~ .radio-checkmark::after {
            display: block;
            content: "";
            position: absolute;
        }

        &.radio-checkmark::after {
            top: 50%;
           left: 50%;
           width: 0.7rem;
           height: 0.7rem;
           border-radius: 50%;
           background: blue;
       }
    }
}

table{
    border-collapse: separate;
    border-spacing: 0 0.625rem;

    td:last-child {
        border-radius:0 1rem 1rem 0;
    }

    td:first-child {
        border-radius:1rem 0 0 1rem;
    }
}


/* style scrollbar for Chrome, Safari and Opera */
.table-overflow-cont::-webkit-scrollbar {
    // display: none;

    height: 0.4rem;
}
.table-overflow-cont::-webkit-scrollbar-track {
    background: #F8F8F8;
    border-radius: 5px;

}
.table-overflow-cont::-webkit-scrollbar-thumb:horizontal{
    background: #E84E40;
    border-radius: 10px;

}

table tbody tr {
    height: 1.625rem;  

}
 
.table-body-row {
    background-color: rgba(128, 126, 124, 0.13); 
}

.table-body-row:hover .table-body-td {
    background-color: rgba(128, 126, 124, 0.15); 
}

.my-date-container {
    z-index: 50 !important;
    background-color: #fff !important;
}

.react-datepicker-popper {
    box-sizing: border-box !important;
    z-index: 99999 !important;
    width: 21rem !important;
    box-shadow: 4px 8px 16px 4px #00000014 !important;
    background-color: #fff !important;
}

.react-datepicker-wrapper {
    background-color: #fafafa !important;
}

.react-datepicker__children-container {
    z-index: 9999 !important;
    box-sizing: border-box !important;
    height: auto !important;
    width: 100% !important;
}

.react-datepicker__input-container, .react-datepicker__input-container:focus {
    border: none !important;
    width: auto;
    background-color: #fafafa !important;
}

.react-datepicker__month-container {
    box-sizing: border-box !important;
    height: auto !important;
    width: 100% !important;
    background: #fff !important;
}

.react-datepicker__header__dropdown--scroll {
    padding: 10px !important;
}

.react-datepicker__day-name {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.react-datepicker__year-read-view--selected-year {
    background-color: #c6c6c6 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-size: 15px !important;
    color: #807E7C !important;
    border-radius: 4px !important;
}

.react-datepicker__year-dropdown {
    background-color: #fff !important;
    color: #807E7C !important;
    width: 50% !important;
}

.react-datepicker__year-read-view--down-arrow {
    display: none !important;
}

.react-datepicker__month {
    box-sizing: border-box !important;
    height: 100% !important;
    width: 100% !important;
}

.react-datepicker__header {
    width: 100% !important;
    background-color: #fff !important;
}

.react-datepicker__current-month {
    color: #525252 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding-bottom: 10px !important;
}

.react-datepicker__day {
    height: 25px !important;
    width: 25px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
    margin: 10px !important;
}

.react-datepicker__day--today {
  color: #000 !important;
  background: #EBEBEB !important;
}

.react-datepicker__day--selected {
    background: #E84E40 !important;
    color: #fff !important;
}

.react-datepicker__day-names {
    background: #fff !important;
    z-index: 9999 !important;
    padding: 8px !important;
}

.popper-cn {
    background: #fff !important;
    border-radius: 4px !important;
}

.cal-classname {
    background: none !important;
}

.react-datepicker-ignore-onclickoutside:focus {
    border: none !important;
}


.agility-calendar {
    border: none;
    outline: none;
    background: #fafafa !important;
  
    .react-date-picker__wrapper {
      width: auto;
      border: none;
      outline: none;
    }

    .react-date-picker__inputGroup {
        min-width: none;
    }
  
    .react-date-picker_inputGroup_input {
        
      &:focus {
        border: none;
        outline: none;
        
      }
    }
    .react-date-picker__inputGroup__input {
        font-size: 0.75rem;
        font-weight: 600;
        color: #525252;
        min-width: auto;
        border: none;
        padding: 0;
        height: auto;
    }
    
    .react-date-picker__inputGroup__leadingZero {
        font-size: 0.75rem;
        font-weight: 600;
        color: #525252;
        // padding: 0 5px;
        
    }
    .react-date-picker__inputGroup__input--hasLeadingZero {
        margin-left: 0;
    }
}

.react-datepicker-popper {
left: 20px !important
}