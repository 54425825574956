// .slide-up-animate {
//     // opacity: 0;
//     transform: translate(0, 100px);
//     transition: 1.5s ease;
// }

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slide-down-from-up-content-reveal {
  animation: slide-down-content-revealer;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  transition: 0.1s ease;
}
input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  }
     
  input::-webkit-inner-spin-button,
  .input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
@keyframes slide-down-content-revealer {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
  
    100% {
      opacity: 1;
      transform: translate(0, 3%);
    }
}

.slide-up-content-reveal {
    animation: content-revealer;
    animation-duration: 0.8s;
    animation-timing-function: ease;
    transition: 0.1s ease;
}

@keyframes content-revealer {
    0% {
      opacity: 0;
      transform: translate(0, 30vh);
    }
  
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

.slide-up-content-reveal-sc-m {
  animation: c-revealer;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  transition: 0.1s ease;
}

@keyframes c-revealer {
    0% {
      opacity: 0;
      transform: translate(0, 50%);
    }
  
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

.sub-menu {
    cursor: pointer;
}
.side-dropdown {
    height: 0rem;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    transition: height 2s;
}

.open-side-dropdown {
    flex-grow: 1;
    height: auto;
    transition: height 2s;
    margin-bottom: 2rem;
    background: rgba(232, 78, 64, 0.05);
    margin-top: 0.2rem;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.animate-sidebar-menu {
  animation: fadeIn 700ms ease-in-out !important;
}

#date-popper {
  z-index: 100 !important;
}

.auth-page {
    background-image: url("../assets/images/Sign-in-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

header.scrolled {
    background-color: pink;
    border: 2px solid blue;
}

.pie-class {
    background-color: green;
}


input[type="file"]::file-selector-button {
  border-radius: 0.375rem;
  padding: 0 1rem;
  height: 2rem;
  cursor: pointer;
  background-color: #BDBDBD;
  color: #fff;
  border: none;
  margin-right: 1rem;
  transition: background-color 200ms;
}

.otp-input {
  color: #000000 !important;
  font-size: 2rem;
  font-weight: 600;
  border: 1px solid #525252;
  // padding: 1rem 2rem;
  width: 4rem !important;
  margin: 0 1rem;
  border-radius: 8px;
}
