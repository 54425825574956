.CalendarDay__selected_span {
    // border-radius: 6.1875rem;
    background: rgba(232, 78, 64, 0.10);
    color: #807E7C; //text
    border: none; //default styles include a border
    &:hover {
      background-color: #E84E40;
      border: none;
    }
}
.CalendarDay__selected {
    background: #E84E40;
    color: white;
    border: none;
}
.CalendarDay__selected:hover {
  background: #E84E40
}

.CalendarDay__default {
  border: none;
  // border-radius: 2rem;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  display: none;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #E84E40;
}
.DateInput {
  min-width: 6.5rem;
  max-width: 6.5rem;
  // border: 2px solid blue;
}
.DateInput_input {
  // border: 2px solid red;
  color: #525252;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 11px;
}
.DateInput_input__focused {
  border-bottom: 2px solid #E84E40 ;
}

.DayPicker_transitionContainer {
  border-radius: 2rem !important;
}
.DateRangePicker_picker {
  border-radius: 2rem;
}


.DayPickerNavigation_button__default {
  border: none;
}

.CalendarMonth_caption {
  strong {
    color: #525252;
    font-family: Inter;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
  }
}

.DateRangePickerInput_arrow_svg {
  width: 15px;
}