.button {
    .btn-suffix-icon {
        transition: all 0.3s;
    }
    .btn-prefix-icon {
        transition: all 0.3s;
    }
}

.button:hover .btn-suffix-icon {
    transform: translateX(5px);
}

.button:hover .btn-prefix-icon {
    transform: translateX(-5px);
}

.auth-btn-icon {
    visibility: hidden;
    transition: 1s;
}
.button:hover .auth-btn-icon {
    margin-left: 5px;
    visibility: visible;
    transform: translateX(35px);
}