@import "./tailwind.scss";
@import "./mixins.scss";
@import "./app.scss";
@import "./components.scss";
@import "./capture-shipment.scss";
@import "./button.scss";
@import "./calendar.scss";


* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

p {
    padding: 0;
    margin: 0;
}

.spin-loader {
    border: 2px solid #e84e40;
    border-top: 2px solid #f3f3f3;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
} 

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  }
     
  input::-webkit-inner-spin-button,
  .input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

