@import "./mixins.scss";

.modalCont {
    display: block;
    min-width: 100vw;
    min-height: 100vh;
    @include flex(column, center, center);
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(38, 40, 62, 0.6);
    backdrop-filter: blur(3px);
    overflow: hidden;
    // box-sizing: border-box;
    @include screen("inbetween") {
      width: 100%;

    }
  
    .contentCont {
      min-height: 25vh;
      max-height: 90vh;
      z-index: 100;
      background-color: white;
      // padding: 1rem 0.5rem;
      // border: 2.11639px solid #FFFFFF;
      box-shadow: 0px 27.5131px 118.518px rgba(136, 122, 166, 0.12);
      border-radius: 10px;
      @include flex(column, unset, unset);
      overflow-y: auto;
      overflow-x: auto;
      box-sizing: border-box;
      @include screen("medium") {
        // max-width: 70%;
      }
      @include screen("inbetween") {
        min-width: 80%;
        max-width: 90%;
        width: 100%;
        max-height: 75vh;
      }

      .modal_inner_cont {
        background-image: url("../assets/images/modal-pattern-bg.png");
        background-repeat: no-repeat;
        background-position: center;
      }
  
      .header {
        @include flex(row, space-between, center);
        .title{
          justify-self: flex-start;
          @include bodyFont(18, 27, 500);
          color: #28166F;
        }
        .closeModal {
          cursor: pointer;
          justify-self: flex-end;
          // padding: 0.5rem;
          border-radius: 6px;
        }
      }
      
    } 
}
.hidden {
  display: none;
}